import React from 'react'
import StoryPreview from '~/components/molecules/StoryPreview'
import Button from '~/components/atoms/Button'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Stories({partnerPreviews}) {

  return (
    <section className="my-20 md:my-36">
      <div className="container">
        <h2 className="text-h2 text-center mb-14">All partner stories</h2>
        <div className="grid grid-cols-12 md:gap-x-12 lg:gap-x-20 gap-y-16 mb-12 relative">
          {partnerPreviews.map((post) => (
            <StoryPreview  key={post.id} {...post} className="col-span-12 md:col-span-6 lg:col-span-4" />
          ))}
          <div className=" hidden lg:block absolute w-px top-0 bottom-0 left-[calc(33.333%-.75rem)] bg-navy bg-opacity-10"></div>
          <div className=" hidden lg:block absolute w-px top-0 bottom-0 left-[calc(66.666%+.75rem)] bg-navy bg-opacity-10"></div>
          <div className=" hidden md:block lg:hidden absolute w-px top-0 bottom-0 left-1/2 bg-navy bg-opacity-10"></div>
        </div>
        {/* <FadeInWhenVisible className="flex justify-center">
          <Button url="/partner/" title="View more" className="min-w-[15em] btn--secondary" />
        </FadeInWhenVisible> */}
      </div>
    </section>
  )
}
